import React from 'react'
import Footer from './Footer'
import Navs from './Navs'

const MainPageLayout = ({ children }) => {
    return (
        <>
        <div style={{position: 'relative', overflowX: 'hidden'}}>
            <Navs />
            {/* <div style={{marginTop: '64px', height: '100%'}}> */}
                {children}
            {/* </div> */}
            <Footer />
        </div >
        </>
    )
}

export default MainPageLayout