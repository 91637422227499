import React from 'react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import './About.css'
// import { StyledLink } from '../Links.styled'
import ScrollReveal from 'scrollreveal';


class About extends React.Component {
    refs = React.createRef();
    componentDidMount() {
        const config = {
            reset: false,
            distance: '60px',
            duration: 1000,
            delay: 100,
            origin: 'top',
            easing: 'ease-in',
        };
        ScrollReveal(config)
        ScrollReveal().reveal(this.refs.box1);
        ScrollReveal().reveal(this.refs.box2, {origin: 'bottom', delay: 150});
        ScrollReveal().reveal(this.refs.box3, {origin: 'right', delay: 150});
    }
    render() {
        return (
            <>
                <div className='row'>
                    <div className='header-start column' ref="box1">
                        <h6>ABOUT ME</h6>
                        <p>A mix of determination, panic, and a playlist for every mood swing ...</p>
                    </div>
                    <div className='column'>
                        {/* empty */}
                    </div>
                </div>
                <div className='row1' ref='about'>
                    <div className='column image-column' ref="box2">
                        <img src='/Images/me.png' alt='about-me'/>
                    </div>
                    <div className='column about-content' ref="box3">
                        <p>
                            I am a recent graduate from IIT Kanpur in the Computer Science and Engineering Department.
                            My academic journey has been driven by a strong passion for AI and ML research, where
                            I've consistently sought innovative applications of Artificial Intelligence.
                            <br/><br/>
                            Currently, I am working as a Software Engineer with the amazing team at <a href="https://www.cognition.ai">Cognition</a> :)
                            <br/><br/>
                            My research focus centers on Large Language Models (LLMs) to enhance natural language
                            inference for clinical trials on breast cancer. I am dedicated to perform impactful research to
                            advance technology for the betterment of society.
                        </p>
                        {/* <br/><br/>
                        <StyledLink to='/councils' className='home-button'>
                            <n>Discover</n>
                            <FontAwesomeIcon className='icon-button' icon={faArrowRight} />
                        </StyledLink> */}
                    </div>
                </div>
            </>
        )
    }
}

export default About