import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons'

import './Project.css'

import ScrollReveal from 'scrollreveal'

class Project extends React.Component {
    refs = React.createRef()
    componentDidMount() {
        const config = {
            reset: false,
            distance: '60px',
            duration: 1000,
            delay: 100,
            origin: 'top',
            easing: 'ease-in',
        }
        ScrollReveal(config)
        ScrollReveal().reveal(this.refs.probox1)
        ScrollReveal().reveal(this.refs.probox2, { origin: 'left'})
        ScrollReveal().reveal(this.refs.probox3, { origin: 'right'})
        ScrollReveal().reveal(this.refs.probox4, { origin: 'left' })
        ScrollReveal().reveal(this.refs.probox5, { origin: 'right'})
        ScrollReveal().reveal(this.refs.probox6, { origin: 'left'})
        ScrollReveal().reveal(this.refs.probox7, { origin: 'right' })
        ScrollReveal().reveal(this.refs.probox8, { origin: 'left'})
        ScrollReveal().reveal(this.refs.probox9, { origin: 'right' })
        ScrollReveal().reveal(this.refs.probox10, { origin: 'left' })
        ScrollReveal().reveal(this.refs.probox11, { origin: 'right' })
        ScrollReveal().reveal(this.refs.probox12, { origin: 'left' })
        ScrollReveal().reveal(this.refs.probox13, { origin: 'right'})
    }
    render() {
        return (
            <>
                <div className='pro-header' ref="probox1">
                    <h1>PROJECTS</h1>
              
                </div>
                <div className='pro-content'>
                    <div className='pro-row' ref="probox2">
                        <div className='pro-column1'>
                            <div id='hr' ></div>
                            <div id='bold-sec-hr'></div>
                            <p className='pro-column-h6'>Defense Against Adversarial Attacks</p>
                            <p className='pro-description'>
                                This work is
                                based on enhancing the robustness of targeted classifier models against
                                adversarial attacks. To achieve this, a convolutional autoencoder-based
                                approach is employed that effectively counters adversarial perturbations
                                introduced to the input images by generating images closely resembling
                                the input images, the proposed methodology aims to restore the model's
                                accuracy.
                            </p>
                            <a href="https://arxiv.org/abs/2312.03520" target='_blank' rel="noopener noreferrer"><button className='pro-button'>
                                View paper <FontAwesomeIcon className='icon-button' icon={faArrowRightLong} />
                            </button></a>
                        </div>
                        <div className='pro-column'></div>
                        <div className='pro-column2'>
                            <img src='/Images/projects/defense.png' alt='Defense' />
                        </div>
                    </div>
                    <div className='pro-row' ref="probox3">
                    <div className='pro-column2'>
                            <img src='/Images/projects/kg.png' alt='Legal KG' />
                        </div>
                        <div className='pro-column'></div>
                        <div className='pro-column1'>
                            <div id='hr' ></div>
                            <div id='bold-sec-hr'></div>
                            <p className='pro-column-h6'>Legal Data Augmentation and Knowledge Graph</p>
                            <p className='pro-description'>
                                Analyzed various data augmentation techniques for enrichment
                                of legal text with semantic annotations and performed lemmatization,
                                NER (Named Entity Recognition), sentence splitting, relation extraction
                                etc. using OpenIE extraction techniques on legal documents extracted from
                                indiankanoon.org to create a Knowledge Graph consisting of over 50000 relations and nodes.
                            </p>
                            <a href="https://github.com/Shreyasi2002/Legal-Augmentation" target='_blank' rel="noopener noreferrer"><button className='pro-button'>
                                View project <FontAwesomeIcon className='icon-button' icon={faArrowRightLong} />
                            </button></a>
                        </div>
                    </div>
              
                    <div className='pro-row' ref="probox4">
                    <div className='pro-column1'>
                            <div id='hr' ></div>
                            <div id='bold-sec-hr'></div>
                            <p className='pro-column-h6'>Computer Graphics</p>
                            <p className='pro-description'>
                                Rendered basic 2D and 3D objects with affine transformation and animation using WebGL2.0 in JavaScript. 
                                Implemented Phong and Goraud Shading techniques, Texture Mapping, Environment reflection and Planar
                                Reflection and other advanced techniques like Ray Tracing, Post-processing of textures, multi-pass rendering and shadow mapping.
                            </p>
                            <a href="https://github.com/Shreyasi2002/Computer-Graphics" target='_blank' rel="noopener noreferrer"><button className='pro-button'>
                                View project <FontAwesomeIcon className='icon-button' icon={faArrowRightLong} />
                            </button></a>
                        </div>
                        <div className='pro-column'></div>
                        <div className='pro-column2'>
                            <img src='/Images/projects/cgraphics.png' alt='Computer Graphics' />
                        </div>
                    </div>
              
                    <div className='pro-row' ref="probox5">
                    <div className='pro-column2'>
                            <img src='/Images/projects/nlp.png' alt='NLP' />
                        </div>
                        <div className='pro-column'></div>
                        <div className='pro-column1'>
                            <div id='hr' ></div>
                            <div id='bold-sec-hr'></div>
                            <p className='pro-column-h6'>Statistical Natural Language Processing</p>
                            <p className='pro-description'>
                                Implemented a Sequence to Sequence (Seq2Seq) network with bi-directional LSTM
                                and Attention mechanism to perform Neural Machine Translation (NMT) from Hindi to English,
                                getting a BLEU scoreof 0.059.
                                <br /><br/>
                                Also, performed Sentiment Analysis using Recurrent Neural Networks (RNNs)
                                with global average pooling and pre-trained word embeddings to classify sentiments into three categories,
                                getting an F1 score of 0.638.
                            </p>
                            {/* <button className='pro-button'>
                                View project <FontAwesomeIcon className='icon-button' icon={faArrowRightLong} />
                            </button> */}
                        </div>
                        
                    </div>
                    <div className='pro-row' ref="probox6">
                    
                        <div className='pro-column1'>
                            <div id='hr' ></div>
                            <div id='bold-sec-hr'></div>
                            <p className='pro-column-h6'>Java Compiler using C++</p>
                            <p className='pro-description'>
                                Created a basic Java compiler in C++, to translate Java source code to
                                x86_64 architecture-based Assembly language. Performed lexical, syntactic and semantic
                                analysis using Flex and Bison libraries, created Abstract Syntax Tree (AST) of the
                                source code, generated appropriate error messages, created Intermediate Representation (IR) of
                                the source code, handled storage allocation on Control Stack and created highly portable assembly language code.
                            </p>
                            <a href="https://github.com/CS335A-Compilers/CS335A-Compilers" target='_blank' rel="noopener noreferrer"><button className='pro-button'>
                                View project <FontAwesomeIcon className='icon-button' icon={faArrowRightLong} />
                            </button></a>
                        </div>
                        <div className='pro-column'></div>
                        <div className='pro-column2'>
                            <img src='/Images/projects/compiler.png' alt='Compiler' />
                        </div>
                    </div>
              
                    <div className='pro-row' ref="probox7">
                    <div className='pro-column2'>
                            <img src='/Images/projects/captcha.png' alt='Captcha' />
                        </div>
                        <div className='pro-column'></div>
                    <div className='pro-column1'>
                            <div id='hr' ></div>
                            <div id='bold-sec-hr'></div>
                            <p className='pro-column-h6'>DeCAPTCHA</p>
                            <p className='pro-description'>
                                Performed data pre-processing to fine-grain the captchas - removing background
                                using binary threshold inversion and Otsu algorithm, performed erosion and dilation and using Contour Detection to
                                localize objects in the captcha. Trained a CNN model with global average pooling on a augmented dataset of 6k images, getting an accuracy of 99.82%.
                            </p>
                            <a href="https://github.com/Shreyasi2002/DeCAPTCHA" target='_blank' rel="noopener noreferrer"><button className='pro-button'>
                                View project <FontAwesomeIcon className='icon-button' icon={faArrowRightLong} />
                            </button></a>
                        </div>
                  
                        
                    </div>

                    <div className='pro-row' ref="probox8">
                        <div className='pro-column1'>
                            <div id='hr' ></div>
                            <div id='bold-sec-hr'></div>
                            <p className='pro-column-h6'>Software Development and Operations</p>
                            <p className='pro-description'>
                                Developed the frontend of a cross-platform web application for
                                the integration of different councils and fests of IIT Kanpur using ReactJS,
                                ensuring a modular format for easy maintainability and addition of new contents. Also, implemented
                                a library management system in C++ language on CLI using object-oriented programming concepts
                                with login/logout functionality.
                            </p>
                            <a href="https://github.com/Shreyasi2002/Software_Development_Operations" target='_blank' rel="noopener noreferrer"><button className='pro-button'>
                                View project <FontAwesomeIcon className='icon-button' icon={faArrowRightLong} />
                            </button></a>
                        </div>
                        <div className='pro-column'></div>
                        <div className='pro-column2'>
                            <img src='/Images/projects/software.png' alt='Software' />
                        </div>
                    </div>
                    <div className='pro-row' ref="probox9">
                    <div className='pro-column2'>
                            <img src='/Images/projects/sudoku.png' alt='Sudoku' />
                        </div>
                        <div className='pro-column'></div>
                        <div className='pro-column1'>
                            <div id='hr' ></div>
                            <div id='bold-sec-hr'></div>
                            <p className='pro-column-h6'>Sudoku Solver and Generator</p>
                            <p className='pro-description'>
                                Built a SAT Solver in Python using DPLL (Davis-Putnam-Logemann-Loveland) algorithm and
                                Jersolow-Wang 2-sided heuristic, taking 6 secs to solve nearly 100 CNF
                                benchmarks depending on the complexity. Also, built a sudoku-pair solver by
                                encoding the problem to propositional logic and solving it via PySAT-Glucose4.1 SAT
                                solver. Extended the problem to generate a random sudoku-pair solution board.
                            </p>
                            <a href="https://github.com/Shreyasi2002/SAT_Solver___Sudoku" target='_blank' rel="noopener noreferrer"><button className='pro-button'>
                                View project <FontAwesomeIcon className='icon-button' icon={faArrowRightLong} />
                            </button></a>
                        </div>
                    </div>
              
                    <div className='pro-row' ref="probox10">
                    <div className='pro-column1'>
                            <div id='hr' ></div>
                            <div id='bold-sec-hr'></div>
                            <p className='pro-column-h6'>Altered Effective Connectivity in Sensorimotor Cortices</p>
                            <p className='pro-description'>
                            Worked under the mentorship of Dipanjan Ray, Postdoctoral Researcher, BCBL Spain to implement Dynamic Causal Modeling (DCM) & Dynamic Connectivity Analysis on Leipzig and Mind-Body dataset using MATLAB. 
                                <br /><br />
                                We used resting-state functional MRI data and Dynamic Causal Modeling (DCM) to assess the hypothesis that depression is associated with aberrant effective connectivity within and between key regions in the sensorimotor hierarchy.
                            </p>
                            {/* <button className='pro-button'>
                                View project <FontAwesomeIcon className='icon-button' icon={faArrowRightLong} />
                            </button> */}
                        </div>
                        <div className='pro-column'></div>
                        <div className='pro-column2'>
                            <img src='/Images/projects/brain.png' alt='Brain' />
                        </div>
                    </div>

                    <div className='pro-row' ref="probox11">
                    <div className='pro-column2'>
                            <img src='/Images/projects/speech.png' alt='Speech' />
                        </div>
                        <div className='pro-column'></div>
                        <div className='pro-column1'>
                            <div id='hr' ></div>
                            <div id='bold-sec-hr'></div>
                            <p className='pro-column-h6'>Speech Emotion Recognition</p>
                            <p className='pro-description'>
                                Guided by Brain and Cognitive Society, IIT Kanpur, I trained NLP-based model using Tensorflow to
                                detect among 8 basic emotions of the speaker using RAVDESS dataset, extracted
                                various features of the audio like ZCR, Spectral Centroid, MFCC and Chroma
                                Frequency. Finally, implemented MLP, CNN and LSTM models to categorize given
                                data to non-linearly separated groups, getting an accuracyof 73%.
                            </p>
                            <a href="https://github.com/Shreyasi2002/Speech-Emotion-Recognition--1" target='_blank' rel="noopener noreferrer"><button className='pro-button'>
                                View project <FontAwesomeIcon className='icon-button' icon={faArrowRightLong} />
                            </button></a>
                        </div>
                        
                    </div>
                    <div className='pro-row' ref="probox12">
                    
                        <div className='pro-column1'>
                            <div id='hr' ></div>
                            <div id='bold-sec-hr'></div>
                            <p className='pro-column-h6'>Box Office</p>
                            <p className='pro-description'>
                                A Progressive Web Application (PWA) bootstrapped with Create React App (CRA) template. It displays data about different TV shows and actors from the TVMaze API.
                                Integrated YouTubeAPI to display the trailers of the TV shows and enhanced functionality and user
                                engagement by adding a puzzle with different levels of difficulty.
                            </p>
                            <a href="https://shreyasi2002.github.io/Box-Office/#/" target='_blank' rel="noopener noreferrer"><button className='pro-button'>
                                View website <FontAwesomeIcon className='icon-button' icon={faArrowRightLong} />
                            </button></a>
                        </div>
                        <div className='pro-column'></div>
                        <div className='pro-column2'>
                            <img src='/Images/projects/boxoffice.png' alt='Box Office' />
                        </div>
                    </div>
              
                    <div className='pro-row' ref="probox13">
                    <div className='pro-column2'>
                            <img src='/Images/projects/chat.png' alt='Chat' />
                        </div>
                        <div className='pro-column'></div>
                    <div className='pro-column1'>
                            <div id='hr' ></div>
                            <div id='bold-sec-hr'></div>
                            <p className='pro-column-h6'>Chat With Me</p>
                            <p className='pro-description'>
                                A progressive chat application bootstrapped with the Create React App (CRA) template and Firebase integration. React Suite was used to get a sensible UI design, and a friendly development experience. 
                                Facebook and Google authentications were added to the chat application. Real-time Presence listener was added for the users with the time they were last seen online. The chat messages were grouped by dates. The user will have the option to delete their message and like them. 
                            </p>
                            <a href="https://chat-with-me-ba33a.web.app/" target='_blank' rel="noopener noreferrer"><button className='pro-button'>
                                View website <FontAwesomeIcon className='icon-button' icon={faArrowRightLong} />
                            </button></a>
                        </div>
                  
                        
                    </div>
                </div>
            </>
        )
    }
}

export default Project