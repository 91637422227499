import React from 'react'

import './Publications.css'
import ScrollReveal from 'scrollreveal';

class Publications extends React.Component {
    refs = React.createRef();
    componentDidMount() {
        const config = {
            reset: false,
            distance: '60px',
            duration: 1000,
            delay: 50,
            origin: 'top',
            easing: 'ease-in-out',
        };
        ScrollReveal(config)
        ScrollReveal().reveal(this.refs.pubbox1);
        ScrollReveal().reveal(this.refs.pubbox2, { origin: 'left', delay: 100 });
        ScrollReveal().reveal(this.refs.pubbox3, { origin: 'left', delay: 500 });
    }
    render() {
        return (
            <>
            <div className='publication-header' ref='pubbox1'>
                    <h4>PUBLICATIONS</h4>
                    <div id='hr' ></div>
                    <div id='bold-sec-hr'></div>
            </div>
            <div className='publication-content'>
                    <ol>
                    <li ref="pubbox1">
                            <b>Shreyasi M.</b>, Ashutosh M., "IITK at SemEval-2024 Task2: Exploring the
                            Capabilities of LLMs for Safe Biomedical Natural Language
                            Inference for Clinical Trials", In Proceedings of the 18th International Workshop
                            on Semantic Evaluation (<b>SemEval-2024</b>), Association for Computational Linguistics. (In Press)
                    </li>
                    <br />
                    <li ref="pubbox2">
                        <b>Shreyasi M.</b>, Srinjoy B. & Elodie L., Using Attention-based Convolutional
                        Auto-Encoders for Catheter Path Reconstruction in Ultrasound Images, 
                        &thinsp;<i>Medical Imaging 2024 : Image-Guided Procedures, Robotic Interventions, and
                        Modeling</i>, <b>SPIE (2024)</b>
                    </li>
                    <br />
                    <li ref="pubbox3">
                        <b>Mandal, Shreyasi.</b> "Defense Against Adversarial Attacks using Convolutional Auto-Encoders." <i>arXiv preprint arXiv:2312.03520</i> (2023).
                    </li>
                </ol>
            </div>
        </>
        )
    }
}

export default Publications