import React from 'react';
import ReactDOM from 'react-dom';

import 'semantic-ui-css/semantic.min.css';

import './index.css'

import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { HashRouter } from 'react-router-dom';
import ScrollToTop from './components/misc/ScrollToTop';
import ScrollToAnchor from './components/misc/ScrollToAnchor';

ReactDOM.render(
    <HashRouter>
        <ScrollToAnchor />
        <ScrollToTop>
            <App />
        </ScrollToTop>
  </HashRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
