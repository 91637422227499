import React from 'react';

import './Testimonial.css';
import { Star } from './styled';

import ScrollReveal from 'scrollreveal';

class Testimonial extends React.Component {
    refs = React.createRef();
    componentDidMount() {
        const config = {
            reset: false,
            distance: '60px',
            duration: 1000,
            delay: 50,
            origin: 'top',
            easing: 'ease-in',
        };
        ScrollReveal(config)
        ScrollReveal().reveal(this.refs.testbox1, { delay: 50 });
        ScrollReveal().reveal(this.refs.testbox3, { origin: 'right', delay: 100 });
        ScrollReveal().reveal(this.refs.testbox2, { origin: 'left', delay: 150 });
        ScrollReveal().reveal(this.refs.testbox3, { origin: 'right', delay: 200 });
    }
    render() {
        return (
            <>
                <div className='testimonial-header' ref="testbox1">
                    <h4>TESTIMONIALS</h4>
                    <div id='hr' ></div>
                    <div id='bold-sec-hr'></div>
                </div>
                <div className='testimonial-content'>
                    <div className='testimonial-row'>
                    <div className='testimonial-col' ref='testbox4'>
                            <div className='test-content-grid'>
                                <div className='test-stars'>
                                    <Star active />
                                    <Star active />
                                    <Star active />
                                    <Star active />
                                    <Star active />
                                </div>
                                <div className='test-content'>
                                    <p className='test-text'>Shreyasi is smart, highly motivated, and a bright software engineer with a great future ahead of her. She interned briefly at 2Sigma School, during which the quality of her work was stellar, and her communication was clear and prompt. Any organization is lucky to have her!</p>
                                </div>
                                <div className='test-name'>
                                    <div className='test-img'>
                                        <img src='/Images/male.png' alt='female-placeholder' />
                                    </div>
                                    <div className='test-name-text'>
                                        <p></p>
                                        <b>Vishal Goenka</b>
                                        <br/><br/>
                                        <p>CEO and Founder
                                            <br />2SigmaSchool</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='testimonial-col' ref='testbox2'>
                            <div className='test-content-grid'>
                                <div className='test-stars'>
                                    <Star active />
                                    <Star active />
                                    <Star active />
                                    <Star active />
                                    <Star active />
                                </div>
                                <div className='test-content'>
                                    <p className='test-text'>Shreyasi was an excellent intern and, within only a couple of months, completed a difficult research project and wrote a
                                        conference paper about it. It was a pleasure to work with Shreyasi.</p>
                                </div>
                                <div className='test-name'>
                                    <div className='test-img'>
                                        <img src='/Images/female.png' alt='female-placeholder' />
                                    </div>
                                    <div className='test-name-text'>
                                        <p></p>
                                        <b>Dr. Elodie Lugez</b>
                                        <br/><br/>
                                        <p>Assistant Professor, CS
                                            <br />Toronto Metropolitan University</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='testimonial-col1' ref='testbox3'>
                            <div className='test-content-grid'>
                                <div className='test-stars'>
                                    <Star active />
                                    <Star active />
                                    <Star active />
                                    <Star active />
                                    <Star active />
                                </div>
                                <div className='test-content'>
                                    <p className='test-text'>I found Shreyasi to be an excellent
                                        engineer with great work ethics and hunger to learn more. In the short
                                        time she spent so far, she was able to explore and pick up completely
                                        new set of research areas and quickly started contributing to the
                                        research problem given to her. She has great appetite for learning and
                                        research.</p>
                                </div>
                                <div className='test-name'>
                                    <div className='test-img'>
                                        <img src='/Images/male.png' alt='male-placeholder' />
                                    </div>
                                    <div className='test-name-text'>
                                        <p></p>
                                        <b>Hima P. Karanam</b>
                                        <br/><br/>
                                        <p>Software Engineer
                                            <br />IBM Research</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Testimonial