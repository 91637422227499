import React from 'react'

import './Video.css'

const Video = () => {
    return (
    <>
        <div className='vid-container'>
          <img src='/Images/background.gif' alt='background' className='image'/>
          <div className='vid-content'>
              <h1>SHREYASI MANDAL</h1>
          </div>
            </div>
    </>
  )
}

export default Video