import React from 'react'

import './Experience.css'

import { SkillList } from './styled'

import ScrollReveal from 'scrollreveal'

class Experience extends React.Component {
    refs = React.createRef()
    componentDidMount() {
        const config = {
            reset: false,
            distance: '60px',
            duration: 1000,
            delay: 50,
            origin: 'top',
            easing: 'ease-in',
        }
        ScrollReveal(config)
        ScrollReveal().reveal(this.refs.expbox1)
        ScrollReveal().reveal(this.refs.expbox2, { origin: 'left', delay: 150 })
        ScrollReveal().reveal(this.refs.expbox3, { origin: 'right', delay: 200 })
        ScrollReveal().reveal(this.refs.expbox4, { origin: 'left', delay: 250 })
        ScrollReveal().reveal(this.refs.expbox5, { origin: 'right', delay: 100 })
        ScrollReveal().reveal(this.refs.expbox5, { origin: 'right', delay: 50 })
    }
    render() {
        return (
            <>
                <div className='exp-header' ref="expbox1">
                    <h1>EXPERIENCE</h1>
              
                </div>
                <div className='exp-content'>
                <div className='exp-row' ref="expbox6">
                        <div className='exp-column1'>
                            <div id='hr' ></div>
                            <div id='bold-sec-hr'></div>
                            <p className='exp-column-h6'>Artificial Intelligence QA Engineer <br /> Scale AI</p>
                            <p className='exp-date'>January 2024 - April 2024</p>
                            <p className='exp-description'>
                            Aided in training Large Language Models (LLMs) by providing high-quality responses to coding prompts and reviewing submitted responses to ensure correctness according to project specifications.
                                Also, conducted quality assurance on AI generated text responses to improve the performance of LLM models.
                            </p>
                            <div id='exp-skill'>
                                {/* Skills:{' '} */}
                                <SkillList>
                                    <span key='1'>LLMs</span>
                                    <span key='2'>QA</span>
                                    <span key='3'>Quality Assurance</span>
                                </SkillList>
                            </div>
                        </div>
                        <div className='exp-column2'>
                            <img src='/Images/experience/qa.png' alt='Quality Assurance' />
                        </div>
                    </div>
                <div className='exp-row' ref="expbox5">
                        <div className='exp-column1'>
                            <div id='hr' ></div>
                            <div id='bold-sec-hr'></div>
                            <p className='exp-column-h6'>Artificial Intelligence Intern<br /> 2SigmaSchool</p>
                            <p className='exp-date'>February 2024 - April 2024</p>
                            <p className='exp-description'>
                            Building a next-generation AI technology to empower students to
                                learn efficiently and for teachers to be productive and
                                engaging in teaching through fine-tuning & prompt-engineering for LLM models.
                            </p>
                            <div id='exp-skill'>
                                {/* Skills:{' '} */}
                                <SkillList>
                                    <span key='1'>LLMs</span>
                                    <span key='2'>Selenium</span>
                                    <span key='3'>Python</span>
                                    <span key='4'>Multi-agent learning</span>
                                </SkillList>
                            </div>
                        </div>
                        <div className='exp-column2'>
                            <img src='/Images/experience/school.png' alt='School' />
                        </div>
                    </div>
                    <div className='exp-row' ref="expbox2">
                        <div className='exp-column1'>
                            <div id='hr' ></div>
                            <div id='bold-sec-hr'></div>
                            <p className='exp-column-h6'>MITACS Research Intern<br /> Toronto Metropolitan University</p>
                            <p className='exp-prof-name'>Prof. Elodie Lugez</p>
                            <p className='exp-date'>May 2023 - July 2023</p>
                            <p className='exp-description'>
                                Worked under the mentorship of Prof. Elodie Lugez on the development of a
                                novel light-weight spatial attention-based Autoencoder CNN that accurately
                                and rapidly segments catheters in volumetric ultrasound 3D images for
                                high-dose-rate (HDR) prostate brachytherapy, detecting approximately 98%
                                of tested catheter paths, with mean tip errors ranging from 0.067 to 0.311 mm.
                            </p>
                            <div id='exp-skill'>
                                {/* Skills:{' '} */}
                                <SkillList>
                                    <span key='1'>AutoEncoders</span>
                                    <span key='2'>CNN</span>
                                    <span key='3'>Medical Imaging</span>
                                    <span key='4'>PyTorch</span>
                                </SkillList>
                            </div>
                        </div>
                        <div className='exp-column2'>
                            <img src='/Images/experience/cancer.png' alt='Radiation-Therapy' />
                        </div>
                    </div>
                    <div className='exp-row' ref="expbox3">
                        <div className='exp-column1'>
                            <div id='hr' ></div>
                            <div id='bold-sec-hr'></div>
                            <p className='exp-column-h6'>Research Intern<br /> IBM Research</p>
                            <p className='exp-date'>May 2022 - July 2022</p>
                            <p className='exp-description'>
                                Worked on improving Knowledge Base Question Answering (KBQA)
                                by solving problems of incomplete KB & inaccurate entity / relation
                                linking using targeted extraction from textual resources mainly, Wikipedia
                                getting an F1 score of 0.416 on the TempQA-WD dataset (based mainly on temporal
                                reasoning) consisting of 839 questions.
                            </p>
                            <div id='exp-skill'>
                                {/* Skills:{' '} */}
                                <SkillList>
                                    <span key='1'>NLP</span>
                                    <span key='2'>Knowledge Graph</span>
                                    <span key='3'>AMR</span>
                                    <span key='4'>SPARQL</span>
                                </SkillList>
                            </div>
                        </div>
                        <div className='exp-column2'>
                            <img src='/Images/experience/dbpedia.png' alt='DBPedia KG' />
                        </div>
                    </div>
              
                    <div className='exp-row' ref="expbox4">
                        <div className='exp-column1'>
                            <div id='hr' ></div>
                            <div id='bold-sec-hr'></div>
                            <p className='exp-column-h6'>Machine Learning Intern<br /> MPYG</p>
                            <p className='exp-date'>Jan 2022 - Feb 2022</p>
                            <p className='exp-description'>
                                Worked on building a model to suggest posture classification using TensorFlow
                                with real-time user feed extracted from the 3D landmarks inferred
                                from MediaPipe and a custom dataset with 8000 images achieving an
                                accuracy of nearly 98%.
                            </p>
                            <div id='exp-skill'>
                                {/* Skills:{' '} */}
                                <SkillList>
                                    <span key='1'>Python</span>
                                    <span key='2'>TensorFlow</span>
                                    <span key='3'>MediaPipe</span>
                                    <span key='4'>Data Collection</span>
                                </SkillList>
                            </div>
                        </div>
                  
                        <div className='exp-column2'>
                            <img src='/Images/experience/mediapipe.png' alt='Mediapipe' />
                        </div>
                    </div>
              
                    {/* <div className='exp-row'>
                <div className='exp-column2'>
                    <img src='/Images/experience/sdg.jpg' alt='SDG AI Lab'/> 
                </div>
                <div className='exp-column1'>
                  <div id='hr' ></div>
                      <div id='bold-sec-hr'></div>
                        <p className='exp-column-h6'>Volunteer Data Scientist<br/> SDG AI Lab, UNDP</p>
                        <p className='exp-date'>May 2022 - July 2022</p>
                        <div id='exp-skill'>
                            {/* Skills:{' '} */}
                    {/* <SkillList>
                                <span key='1'>Data Science</span>
                                <span key='2'>NLP</span>
                                <span key='3'>NER</span>
                                <span key='4'>Open Source</span>
                            </SkillList>
                        </div>
                 </div>     
              </div>     */}
                </div>
            </>
        )
    }
}

export default Experience